<template>
  <v-main class="overflow-y-auto">
    <v-responsive
      class="d-flex align-center fill-height"
    >
      <v-container
        class="fill-height justify-center"
        tag="section"
      >
        <v-row
          justify="center"
          class="mb-4"
        >
          <v-slide-y-transition appear>
            <img
              :src="require('@/assets/images/logo-odevia.png')"
              alt=""
              class="logo-mini"
            >
          </v-slide-y-transition>
        </v-row>

        <v-row
          justify="center"
          class="mb-5"
        >
          <h2
            class="text-h3 font-italic mb-2 primary--text"
          >
            {{ $parameter('CUSTOMER_NAME') }}
          </h2>
        </v-row>

        <v-row justify="center">
          <v-slide-x-transition
            mode="out-in"
            appear
          >
            <router-view />
          </v-slide-x-transition>
        </v-row>
      </v-container>
    </v-responsive>

    <core-flash />
  </v-main>
</template>

<script>
  export default {
    components: {
      CoreFlash: () => import('@/components/core/Flash'),
    },
  };
</script>
